.search-doc-wrap {	
	margin: 10px 0;
	.title{
		width: 100px;
	}
	form{
		> .form-box {
			width: 100%;
			overflow: hidden;
			.ant-form-item{
				float: left;
				width: 25%;
			}
			.ant-form-item-label{
				//text-align: left;
				padding-left: 5px;
			}
		}
		.btns{
			text-align: right;
			padding-right: 0px;
			button{
				margin-left: 10px;
			}
		}
	}
}
// .search-office-wrap form > .form-box .ant-form-item{
// 	min-width: 220px;
// }